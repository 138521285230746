section {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.chat {
  display: grid;
  word-wrap: anywhere;
  white-space: pre-wrap;
  height: 100%;
  padding: 1em;
}

.user, .assistant, .error {
  margin: 0.5em 0em 0em 0em;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.assistant {
  /* light blue */
  background-color: #f0f8ff;
  place-self: start;
  margin-right: 2em;
}
.user {
  place-self: end;
  margin-left: 2em;
}
.error {
  /* light red */
  background-color: #ffcccc;
  place-self: start;
}

/* No margin if the message is from the same user */
.user + .user, .assistant + .assistant {
  margin-top: 0.1em;
}

.assistant:has(.loading) {
  width: 3em;
}
.loading {
  width: 0.8em;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 1em 0 #000, 2em 0 #0002;background: #000 }
    33% {box-shadow: 1em 0 #000, 2em 0 #0002;background: #0002}
    66% {box-shadow: 1em 0 #0002,2em 0 #000; background: #0002}
    100%{box-shadow: 1em 0 #0002,2em 0 #000; background: #000 }
}

.editor {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 0.5em;
  margin: 1em;
}
textarea {
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  box-sizing: border-box;
  height: 40px;
  overflow: hidden;
}
button {
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: fit-content;
}
