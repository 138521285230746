header {
  height: 3em;
  color: rgb(var(--accent-foreground-rgb));
  background-color: rgb(var(--accent-background-rgb));
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header > nav {
  display: none;
}

header > input {
  display: none;
}

header > input:checked ~ nav {
  display: block;
  position: absolute;
  top: 3em;
  width: 100%;
  right: 0px;
  background-color: rgb(var(--accent-background-rgb));
}

header > nav > ul {
  list-style: none;
  padding: 0em 1em 0.75em 1em;
}

header > nav > ul li {
  margin: 0.25em;
}
